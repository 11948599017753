import {
  ResEntrainChildPoints,
  ResEntrainAdultPoints,
  ResEntrainAdultPointsAdultPoints,
  ResEntrainAdultPointsBreakdown,
  ResEntrainBusPoints,
  ResEntrainBusPointsBusPoints,
  ResEntrainBusPointsBusPointsBusRideHistory
} from '@/gen/shiva';

// Breakdown 乗車ポイント内訳クラス
// AdultPointクラスの中の乗車ポイント内訳を格納する目的で利用する。
// 内訳に関するデータ1件を格納する。
export class Breakdown {
  private constructor(
    readonly rate: number,
    readonly rateCountMin: string,
    readonly rateCountMax: string,
    readonly count: number
  ) {}

  static valueOf(data: ResEntrainAdultPointsBreakdown) {
    return new Breakdown(
      data.rate,
      data.rate_count_min,
      data.rate_count_max,
      data.count
    );
  }
}

// BreakdownList Breakdownクラスの配列を返すクラス
// APIから返された内訳データを、独自定義したクラスに移し替える目的で利用する。
// APIから返された内訳データの配列をBreakdownクラスに移し替え、その配列を返す。
export class BreakdownList {
  private constructor() {}

  static valueOf(data: ResEntrainAdultPointsBreakdown[]) {
    return data.map(e => Breakdown.valueOf(e));
  }
}

// AdultPoints 大人用乗降車ポイント情報クラス
// EntrainAdultPointsクラスの中のポイント情報を格納する目的で利用する。
// 区間ごとのポイント情報1件を格納する。
export class AdultPoints {
  private constructor(
    readonly fare: number,
    readonly rate: number,
    readonly count: number,
    readonly point: number,
    readonly nextRateCount: number,
    readonly nextRate: number,
    readonly breakdown: Breakdown[]
  ) {}

  static valueOf(data: ResEntrainAdultPointsAdultPoints) {
    const breakdown = BreakdownList.valueOf(data.breakdown);
    return new AdultPoints(
      data.fare,
      data.rate,
      data.count,
      data.point,
      data.next_rate_count,
      data.next_rate,
      breakdown
    );
  }
}

// AdultPointsList AdultPointsクラスの配列を返すクラス
// APIから返された区間ごとのポイントデータを、独自定義したクラスに移し替える目的で利用する。
// APIから返された区間ごとのポイントデータの配列をAdultPointsクラスに移し替え、その配列を返す。
export class AdultPointsList {
  private constructor() {}

  static valueOf(data: ResEntrainAdultPointsAdultPoints[]) {
    return data.map(e => AdultPoints.valueOf(e));
  }
}

// EntrainAdultPoints 大人用乗降車ポイントデータクラス
// 画面に大人用乗降車ポイントを表示する目的で利用する。
// APIから返されたデータのうち、ポイントデータはAdultPointListクラスに格納した上で、自身のインスタンスを返す
export class EntrainAdultPoints {
  private constructor(
    readonly adultPoints: AdultPoints[],
    readonly latestOperatedDate: string
  ) {}

  static valueOf(data: ResEntrainAdultPoints) {
    const adultPoints = AdultPointsList.valueOf(data.adult_points);
    return new EntrainAdultPoints(adultPoints, data.latest_operated_date);
  }
}

// BusRideHistory ポイント数内訳クラス
// BusPointsクラスの中のポイント数内訳を格納する目的で利用する。
// 内訳に関するデータ一件を格納する。
export class BusRideHistory {
  private constructor(readonly busRideDt: string, readonly point: number) {}
  static valueOf(data: ResEntrainBusPointsBusPointsBusRideHistory) {
    return new BusRideHistory(data.bus_ride_dt, data.point);
  }
}

// BusRideHistoryList BusRideHistoryクラスの配列を返すクラス
// APIから返されたポイント数の内訳を、独自定義したクラスに移し替える目的で利用する。
// APIから返されたポイント数の内訳の配列をBusRideHistoryクラスに移し替え、その配列を返す。
export class BusRideHistoryList {
  private constructor() {}

  static valueOf(data: ResEntrainBusPointsBusPointsBusRideHistory[]) {
    return data.map(e => BusRideHistory.valueOf(e));
  }
}

// BusPoints バス用乗降車ポイント情報クラス
// BusRidePointsクラスの中のポイント情報を格納する目的で利用する。
// 合計ポイント数と乗車履歴のリストを返す。。
export class BusPoints {
  private constructor(
    readonly totalPoint: number | null,
    readonly busRideHistory: BusRideHistory[]
  ) {}
  static valueOf(data: ResEntrainBusPointsBusPoints) {
    const busRideHist = BusRideHistoryList.valueOf(data.bus_ride_history);
    return new BusPoints(data.total_point, busRideHist);
  }
}

// BusRidePoints バス乗降車ポイントデータクラス
// 画面にバス乗降車ポイントを表示する目的で利用する。
// APIから返されたデータを格納し、自身のインスタンスを返す。
export class BusRidePoints {
  private constructor(
    readonly busPoints: BusPoints,
    readonly latestOperatedDate: string
  ) {}
  static valueOf(data: ResEntrainBusPoints) {
    const BusRideHistory = BusPoints.valueOf(data.bus_points);
    return new BusRidePoints(BusRideHistory, data.latest_operated_datetime);
  }
}

// EntrainChildPoints 小児用乗降車ポイントデータクラス
// 画面に小児用乗降車ポイントを表示する目的で利用する。
// APIから返されたデータを格納し、自身のインスタンスを返す。
export class EntrainChildPoints {
  private constructor(
    readonly point: number | null,
    readonly latestOperatedDate: string
  ) {}

  static valueOf(data: ResEntrainChildPoints) {
    return new EntrainChildPoints(data.point, data.latest_operated_date);
  }
}
