import Vue from 'vue';
import * as shivaApi from '@/gen/shiva/api';
import {
  EntrainAdultPoints,
  EntrainChildPoints,
  BusRidePoints
} from '@/models/PasmoEntrain';

export class PasmoEntrainRepository {
  private async entrainApiClient() {
    const config = await Vue.prototype.$getShivaConfigWithToken(
      Vue.prototype.$auth
    );
    return new shivaApi.EntrainApi(config);
  }

  async getAdultPointHistories(ym: string) {
    const client = await this.entrainApiClient();
    try {
      const res = await client.getEntrainAdultPoints(ym);
      const { data } = res;
      return EntrainAdultPoints.valueOf(data);
    } catch (err) {
      const res = (err as any).response;
      return Promise.reject(res.data.code ? res.data.code : res.status);
    }
  }

  async getBusPointHistories(ym: string) {
    const client = await this.entrainApiClient();
    try {
      const res = await client.getEntrainBusPoints(ym);
      const { data } = res;
      return BusRidePoints.valueOf(data);
    } catch (err) {
      const res = (err as any).response;
      return Promise.reject(res.data.code ? res.data.code : res.status);
    }
  }

  async getChildPointHistories(ym: string) {
    const client = await this.entrainApiClient();
    try {
      const res = await client.getEntrainChildPoints(ym);
      const { data } = res;
      return EntrainChildPoints.valueOf(data);
    } catch (err) {
      const res = (err as any).response;
      return Promise.reject(res.data.code ? res.data.code : res.status);
    }
  }
}
