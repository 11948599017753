









































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { OpCardList } from '@/models/opcards/OpCard';
import { PasmoIdiRepository } from '@/repositories/PasmoIdiRepository';
import { PasmoEntrainRepository } from '@/repositories/PasmoEntrainRepository';
import { AdultIdiLinkHistories, ChildPointIdi } from '@/models/PasmoIdi';
import {
  EntrainAdultPoints,
  EntrainChildPoints,
  BusRidePoints
} from '@/models/PasmoEntrain';
import filterUtils from '@/common/filterUtils';
import dayjs from 'dayjs';
import PasmoAppDownloadPanel from '@/components/PasmoAppDownloadPanel.vue';
import OpAppDownloadPanel from '@/components/OpAppDownloadPanel.vue';
import StartNav from '@/components/StartNav.vue';

@Component({
  components: {
    PasmoAppDownloadPanel,
    OpAppDownloadPanel,
    StartNav
  }
})
export default class UsageHistoryAndPointDetail extends Vue {
  adultPasmoPointHistoryData = {} as EntrainAdultPoints;
  busRidePointHistoryData = {} as BusRidePoints;
  childPasmoPointHistoryData = {} as EntrainChildPoints;
  adultPasmoIdiHistoryData: AdultIdiLinkHistories[] = [];
  childPasmoIdiHistoryData: ChildPointIdi[] = [];
  isBreakdownBalloonVisible = false;
  selectedBreakdownBalloon = 0;
  isHisTargetAdult = false;
  isHisTargetChild = false;
  adultPasmoPointHistorySelectedButton = '';
  busRidePointHistorySelectedButton = '';
  childPasmoPointHistorySelectedButton = '';
  isAdultPasmoHistoryLoading = false;
  isBusRideHistoryLoading = false;
  isChildPasmoHistoryLoading = false;

  adultPasmoPointHistoryError = '';
  busRidePointHistoryError = '';
  childPasmoPointHistoryError = '';
  adultPasmoIdiHistoryError = '';
  childPasmoIdiHistoryError = '';

  created() {
    this.initialize();
  }

  initialize() {
    this.getAdultPasmoIdiHistory();
    this.getChildPasmoIdiHistory();
    this.getAdultPasmoPointHistory(dayjs().format('YYYYMM'));
    this.getBusRidePointHistory(dayjs().format('YYYYMM'));
    this.getChildPasmoPointHistory(dayjs().format('YYYYMM'));
  }

  get opCards() {
    return OpCardList.valueOf(
      this.$auth.user['https://one-odakyu.com/op_cards']
    );
  }

  getPasmoIdiRepo() {
    return new PasmoIdiRepository();
  }

  getPasmoEntrainRepo() {
    return new PasmoEntrainRepository();
  }

  getAdultPasmoIdiHistory() {
    this.getPasmoIdiRepo()
      .getAdultIdiLinkHistories()
      .then(res => {
        this.adultPasmoIdiHistoryData = res;
      })
      .catch((errCode: number) => {
        this.handleGetAdultPasmoIdiHistoryErr(errCode);
      });
  }

  handleGetAdultPasmoIdiHistoryErr(errCode: number) {
    switch (errCode) {
      case 50000:
        this.adultPasmoIdiHistoryError = this.$msg.get('2000011');
        break;
      default:
        this.adultPasmoIdiHistoryError = this.$msg.get('2000048');
        break;
    }
  }

  formatRate(rate: number) {
    // APIから返される付与率に100を掛けて%表記に変換
    return rate * 100;
  }

  getChildPasmoIdiHistory() {
    this.getPasmoIdiRepo()
      .getChildIdiLinkHistories()
      .then(res => {
        this.childPasmoIdiHistoryData = res;
      })
      .catch((errCode: number) => {
        this.handleGetChildPasmoIdiHistoryErr(errCode);
      });
  }

  handleGetChildPasmoIdiHistoryErr(errCode: number) {
    switch (errCode) {
      case 50000:
        this.childPasmoIdiHistoryError = this.$msg.get('2000011');
        break;
      default:
        this.childPasmoIdiHistoryError = this.$msg.get('2000048');
        break;
    }
  }

  get isRegisteredChildPasmoIdis() {
    // 小児用IDiが全ての月で紐づいていなければtrueを返す
    if (this.childPasmoIdiHistoryData) {
      for (let i = 0; i < this.childPasmoIdiHistoryData.length; i++) {
        if (this.childPasmoIdiHistoryData[i].childIdis.length > 0) {
          return true;
        }
      }
    }
    return false;
  }

  get ymList() {
    let ymList = [];
    // 当月から月を引く形で、2ヶ月前・1ヶ月前・当月のタブを生成する
    for (let i = 2; i >= 0; i--) {
      ymList.push({
        label: dayjs()
          .subtract(i, 'month')
          .format('YYYY年MM月'),
        value: dayjs()
          .subtract(i, 'month')
          .format('YYYYMM')
      });
    }
    return ymList;
  }

  getAdultPasmoPointHistory(ym: string) {
    this.isBreakdownBalloonVisible = false;
    this.isAdultPasmoHistoryLoading = true;
    this.adultPasmoPointHistoryData = {} as EntrainAdultPoints;
    this.adultPasmoPointHistoryError = '';
    this.getPasmoEntrainRepo()
      .getAdultPointHistories(ym)
      .then(res => {
        this.adultPasmoPointHistoryData = res;
        this.isAdultPasmoHistoryLoading = false;
      })
      .catch((errCode: number) => {
        this.handleGetAdultPasmoPointHistoryErr(errCode);
        this.isAdultPasmoHistoryLoading = false;
      });

    this.adultPasmoPointHistorySelectedButton = ym;
  }

  handleGetAdultPasmoPointHistoryErr(errCode: number) {
    switch (errCode) {
      case 50000:
        this.adultPasmoPointHistoryError = this.$msg.get('2000011');
        break;
      default:
        this.adultPasmoPointHistoryError = this.$msg.get('2000054');
        break;
    }
  }

  getBusRidePointHistory(ym: string) {
    this.isBusRideHistoryLoading = true;
    this.busRidePointHistoryData = {} as BusRidePoints;
    this.busRidePointHistoryError = '';
    this.getPasmoEntrainRepo()
      .getBusPointHistories(ym)
      .then(res => {
        this.busRidePointHistoryData = res;
        this.isBusRideHistoryLoading = false;
      })
      .catch((errCode: number) => {
        this.handleGetBusRidePointHistoryErr(errCode);
        this.isBusRideHistoryLoading = false;
      });

    this.busRidePointHistorySelectedButton = ym;
  }

  handleGetBusRidePointHistoryErr(errCode: number) {
    switch (errCode) {
      case 50000:
        this.busRidePointHistoryError = this.$msg.get('2000011');
        break;
      default:
        this.busRidePointHistoryError = this.$msg.get('2000054');
        break;
    }
  }

  getChildPasmoPointHistory(ym: string) {
    this.isChildPasmoHistoryLoading = true;
    this.childPasmoPointHistoryData = {} as EntrainChildPoints;
    this.childPasmoPointHistoryError = '';
    this.getPasmoEntrainRepo()
      .getChildPointHistories(ym)
      .then(res => {
        this.childPasmoPointHistoryData = res;
        this.isChildPasmoHistoryLoading = false;
      })
      .catch((errCode: number) => {
        this.handleGetChildPasmoPointHistoryErr(errCode);
        this.isChildPasmoHistoryLoading = false;
      });

    this.childPasmoPointHistorySelectedButton = ym;
  }

  handleGetChildPasmoPointHistoryErr(errCode: number) {
    switch (errCode) {
      case 50000:
        this.childPasmoPointHistoryError = this.$msg.get('2000011');
        break;
      default:
        this.childPasmoPointHistoryError = this.$msg.get('2000054');
        break;
    }
  }

  get adultLatestOperatedDate() {
    // バッチ実行日 - 1日 を画面に表示する
    const yesterday = dayjs(this.adultPasmoPointHistoryData.latestOperatedDate)
      .subtract(1, 'day')
      .format();
    return filterUtils.funcs.formatMD(yesterday);
  }

  get busLatestOperatedDate() {
    // バッチ実行日 - 1日 を画面に表示する
    const yesterday = dayjs(this.busRidePointHistoryData.latestOperatedDate)
      .subtract(1, 'day')
      .format();
    return filterUtils.funcs.formatMD(yesterday);
  }

  get childLatestOperatedDate() {
    // バッチ実行日 - 1日 を画面に表示する
    const yesterday = dayjs(this.childPasmoPointHistoryData.latestOperatedDate)
      .subtract(1, 'day')
      .format();
    return filterUtils.funcs.formatMD(yesterday);
  }

  // TODO(j.oiwa.zx@future.co.jp): 内訳表示部分はonclickでの表示とし、同時に表示できる吹き出しは1つまでとしている。
  // 暫定的な書き方のため、デザイン適用時に改修が必要。
  toggleBreakdownBalloon(id: number) {
    if (
      this.isBreakdownBalloonVisible &&
      this.selectedBreakdownBalloon === id
    ) {
      this.isBreakdownBalloonVisible = false;
    } else {
      this.isBreakdownBalloonVisible = true;
      this.selectedBreakdownBalloon = id;
    }
  }

  formatYm(time: string) {
    return filterUtils.funcs.formatYm(time);
  }

  formatYmd(time: string) {
    return filterUtils.funcs.formatYmd(time);
  }

  formatYmdHm(time: string) {
    return filterUtils.funcs.formatYmdHm(time);
  }

  formatYmdHmSlash(time: string) {
    return filterUtils.funcs.formatYmdHmSlash(time);
  }

  toggleHisTargetAdult() {
    this.isHisTargetAdult = !this.isHisTargetAdult;
  }

  toggleHisTargetChild() {
    this.isHisTargetChild = !this.isHisTargetChild;
  }

  //アンカー スムーズスクロール
  toAnchor(e: Event) {
    let target = e.target as HTMLElement;
    let id =
      target instanceof HTMLElement === true ? target.getAttribute('href') : '';
    if (id !== null) {
      id = id.replace('#', '');
    }
    let elem =
      id !== null
        ? (document.getElementById(id) as HTMLElement)
        : new HTMLElement();
    let top =
      elem instanceof HTMLElement === true
        ? window.pageYOffset + elem.getBoundingClientRect().top
        : 0;

    window.scrollTo({
      top: top,
      behavior: 'smooth'
    });
    e.preventDefault();
  }
}
